<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="text-heading-1 text-title">勤怠詳細</div>
        </v-col>
        <v-col cols="12">
          <div class="target-date-user">
            2021年 7月 22日 / 田中 太郎 さんの勤怠報告
          </div>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="6"
              ><div class="total-work-time">5 件 あります。</div></v-col
            >
            <v-col cols="6">
              <div class="add-new-work">
                <a href=""
                  ><i
                    aria-hidden="true"
                    class="v-icon notranslate mdi mdi-plus theme--light"
                  ></i>
                  新しい勤怠を追加する</a
                >
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-card class="work-report-sec">
            <v-row class="sec-1">
              <v-col cols="8">
                <div class="report_id">勤怠ID: W12345678</div>
                <div class="inner-sec">
                  <div class="work-type sec">
                    タイプ <span class="val-sec">Work Type</span>
                  </div>
                  <div class="in-out sec">
                    <v-row>
                      <v-col cols="2" class="in-time"
                        >イン <span class="val-sec">07:35</span></v-col
                      >
                      <v-col cols="2" class="out-time"
                        >アウト <span class="val-sec">02:59</span></v-col
                      >
                    </v-row>
                  </div>
                  <div class="rest-time sec">
                    <v-row>
                      <v-col cols="2" class="day-time"
                        >昼休憩<span class="val-sec"> 90 分</span></v-col
                      >
                      <v-col cols="2" class="night-time"
                        >夜休憩<span class="val-sec"> 180 分</span></v-col
                      >
                      <v-col cols="2" class="tag-time">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 6.89385V1.3125C0 0.587617 0.587617 0 1.3125 0H6.89385C7.24195 1.82021e-06 7.57579 0.138284 7.82193 0.384426L13.6156 6.17808C14.1281 6.69064 14.1281 7.52167 13.6156 8.03423L8.03423 13.6156C7.52167 14.1281 6.69064 14.1281 6.17808 13.6156L0.384426 7.82193C0.138284 7.57579 1.82021e-06 7.24195 0 6.89385H0ZM3.0625 1.75C2.33762 1.75 1.75 2.33762 1.75 3.0625C1.75 3.78738 2.33762 4.375 3.0625 4.375C3.78738 4.375 4.375 3.78738 4.375 3.0625C4.375 2.33762 3.78738 1.75 3.0625 1.75Z"
                            fill="#9B2F3C"
                          />
                        </svg>
                        遅刻

                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 6.89385V1.3125C0 0.587617 0.587617 0 1.3125 0H6.89385C7.24195 1.82021e-06 7.57579 0.138284 7.82193 0.384426L13.6156 6.17808C14.1281 6.69064 14.1281 7.52167 13.6156 8.03423L8.03423 13.6156C7.52167 14.1281 6.69064 14.1281 6.17808 13.6156L0.384426 7.82193C0.138284 7.57579 1.82021e-06 7.24195 0 6.89385H0ZM3.0625 1.75C2.33762 1.75 1.75 2.33762 1.75 3.0625C1.75 3.78738 2.33762 4.375 3.0625 4.375C3.78738 4.375 4.375 3.78738 4.375 3.0625C4.375 2.33762 3.78738 1.75 3.0625 1.75Z"
                            fill="#9B2F3C"
                          />
                        </svg>
                        早退
                      </v-col>
                    </v-row>
                  </div>
                  <div class="wt-memo sec">
                    <v-row>
                      <v-col cols="12" class="in-time"
                        >メモ<span class="val-sec"
                          >大雨の為、主要道路が閉鎖されており渋滞していました</span
                        ></v-col
                      >
                    </v-row>
                  </div>
                </div>
              </v-col>
              <v-col cols="4" class="txt-right">
                <div class="edit_link">
                  <a href="javascript:void(0)" v-on:click="isHidden = !isHidden"
                    >編集
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.70427 0.464844C9.36535 0.478063 9.92883 0.695469 10.3828 1.17233C10.5387 1.33658 10.6965 1.49998 10.8495 1.66733C11.6703 2.56325 11.6696 3.95516 10.8136 4.81986C8.95177 6.70072 7.08019 8.57174 5.19455 10.4284C4.98629 10.6334 4.67494 10.777 4.38638 10.8487C3.65457 11.0315 2.90897 11.1602 2.16774 11.3051C1.21571 11.4911 0.429615 10.7022 0.617911 9.74427C0.765286 8.99544 0.905208 8.2438 1.0921 7.50411C1.16086 7.23172 1.30374 6.94119 1.49865 6.74333C3.35476 4.85699 5.22816 2.9878 7.0989 1.1158C7.54299 0.671422 8.09213 0.475813 8.70427 0.464844ZM1.87229 10.0594C2.66513 9.9282 3.40946 9.80797 4.1521 9.6786C4.22762 9.66552 4.30637 9.61081 4.36247 9.55456C6.2079 7.7038 8.05304 5.85345 9.89185 3.9965C10.3118 3.57209 10.3085 3.01902 9.90648 2.57225C9.77738 2.42881 9.63577 2.29648 9.49908 2.1598C8.96161 1.62317 8.38547 1.62303 7.84843 2.16106C6.16247 3.84983 4.48327 5.54563 2.78579 7.22328C2.47402 7.53139 2.26829 7.84906 2.20866 8.29217C2.13061 8.87169 1.99252 9.44291 1.87229 10.0594Z"
                        fill="#444444"
                      />
                    </svg>
                  </a>
                </div>
                <div class="created_by">
                  <p class="created_date">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.04102 0.816406C10.413 0.825781 13.1983 3.59878 13.1799 6.96791C13.1609 10.3975 10.4396 13.121 7.02102 13.1144C3.58739 13.1082 0.873393 10.3619 0.886768 6.94816C0.899893 3.52566 3.63252 0.851656 7.04102 0.816406ZM7.04489 1.76828C4.17389 1.75391 1.84777 4.06578 1.82952 6.95028C1.81077 9.81978 4.11864 12.1473 7.00514 12.1714C9.88252 12.1949 12.2253 9.86791 12.2331 6.97791C12.2406 4.10366 9.92889 1.78153 7.04489 1.76828Z"
                        fill="#909090"
                      />
                    </svg>
                    2021/07/22 18:24
                  </p>
                  <p class="created_user">
                    <svg
                      width="10"
                      height="12"
                      viewBox="0 0 10 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.89686 10.64C9.87636 8.62172 8.58861 6.80097 6.69249 6.10897C6.62474 6.08434 6.55724 6.05809 6.48374 6.02947L6.24774 5.93847L6.35661 5.87234C7.92036 4.92259 8.10874 3.41647 7.79486 2.41509C7.42961 1.24997 6.34149 0.409969 5.14874 0.372719C5.11574 0.371594 5.08311 0.371094 5.05036 0.371094C3.79436 0.371094 2.71186 1.12347 2.29249 2.28784C1.80999 3.62659 2.23436 4.73397 3.66824 5.87859L3.74249 5.93784L3.65886 5.98247C3.64636 5.98922 3.63474 5.99597 3.62324 6.00259C3.59549 6.01872 3.56936 6.03359 3.54174 6.04372C1.30349 6.87347 0.127363 8.57972 0.140738 10.9652C0.143738 11.5085 0.257238 11.6251 0.814363 11.6251H8.92811C9.39524 11.6251 9.66536 11.6123 9.78224 11.4942C9.89849 11.377 9.90161 11.1025 9.89686 10.64ZM2.98249 3.30009C2.97824 2.75197 3.18786 2.24072 3.57274 1.85034C3.95461 1.46322 4.46236 1.25022 5.00286 1.25022H5.01586C6.13786 1.25022 7.05324 2.15447 7.05661 3.27272C7.05824 3.81559 6.84636 4.32547 6.46011 4.71334C6.07374 5.10147 5.56199 5.31422 5.01924 5.31497C3.90349 5.31497 2.99111 4.41022 2.98249 3.30009ZM9.01624 10.6792L9.01336 10.7502H1.02261L1.02011 10.6787C0.980613 9.68334 1.36324 8.68497 2.09786 7.92059C2.87111 7.11597 3.93999 6.62522 5.03074 6.62522H5.05299C6.13149 6.62522 7.19061 7.12834 7.95849 7.93784C8.68686 8.70559 9.06249 9.69797 9.01624 10.6792Z"
                        fill="#909090"
                      />
                    </svg>
                    By username
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row class="sec-2">
              <v-col cols="6">
                <svg
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 7.0625C8.33027 7.0625 6.5625 8.83027 6.5625 11C6.5625 13.1697 8.33027 14.9375 10.5 14.9375C12.6697 14.9375 14.4375 13.1697 14.4375 11C14.4375 8.83027 12.6697 7.0625 10.5 7.0625ZM20.6062 10.3643L16.7221 8.42422L18.0961 4.30625C18.2807 3.74844 17.7516 3.21934 17.1979 3.40801L13.0799 4.78203L11.1357 0.89375C10.8732 0.36875 10.1268 0.36875 9.86426 0.89375L7.92422 4.77793L3.80215 3.40391C3.24434 3.21934 2.71523 3.74844 2.90391 4.30215L4.27793 8.42012L0.39375 10.3643C-0.13125 10.6268 -0.13125 11.3732 0.39375 11.6357L4.27793 13.5758L2.90391 17.6979C2.71934 18.2557 3.24844 18.7848 3.80215 18.5961L7.92012 17.2221L9.86016 21.1062C10.1227 21.6313 10.8691 21.6313 11.1316 21.1062L13.0717 17.2221L17.1896 18.5961C17.7475 18.7807 18.2766 18.2516 18.0879 17.6979L16.7139 13.5799L20.598 11.6398C21.1313 11.3732 21.1313 10.6268 20.6062 10.3643ZM14.2119 14.7119C12.1652 16.7586 8.83477 16.7586 6.78809 14.7119C4.74141 12.6652 4.74141 9.33477 6.78809 7.28809C8.83477 5.24141 12.1652 5.24141 14.2119 7.28809C16.2586 9.33477 16.2586 12.6652 14.2119 14.7119Z"
                    fill="#F2994A"
                  />
                </svg>
                <span>昼計 <b>6.79 h</b></span>
              </v-col>
              <v-col cols="6">
                <svg
                  class="night-icon"
                  width="16"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 15.5C9.81334 15.5 11.9261 14.4475 13.3259 12.7229C13.533 12.4678 13.3072 12.0951 12.9872 12.156C9.3484 12.849 6.00683 10.059 6.00683 6.38586C6.00683 4.26998 7.1395 2.32429 8.9804 1.27666C9.26417 1.11518 9.1928 0.684951 8.87036 0.625391C8.41833 0.542039 7.95965 0.500068 7.5 0.5C3.36009 0.5 0 3.85481 0 8C0 12.1399 3.35481 15.5 7.5 15.5Z"
                    fill="#F2C94C"
                  />
                </svg>
                <span>夜計 <b>1.23 h</b></span>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="work-report-sec edit-form" v-if="isHidden">
            <v-row class="sec-1">
              <v-col cols="8">
                <div class="report_id">勤怠ID: W12345678</div>
                <div class="inner-sec">
                  <v-row class="work-type sec">
                    <v-col cols="1" class="lbl"><span>タイプ </span></v-col>
                    <v-col cols="2">
                      <v-select
                        class="form-text"
                        dense
                        hide-details
                        outlined
                        placeholder="現場勤務（個）"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="in-out sec">
                    <v-col cols="3" class="in-time">
                      <v-row>
                        <v-col cols="4" class="lbl">イン</v-col>
                        <v-col cols="8"
                          ><v-select
                            class="form-text"
                            dense
                            hide-details
                            outlined
                            placeholder="07:35"
                          ></v-select
                        ></v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="3" class="out-time">
                      <v-row>
                        <v-col cols="4" class="lbl">>アウト</v-col>
                        <v-col cols="8"
                          ><v-select
                            class="form-text"
                            dense
                            hide-details
                            outlined
                            placeholder="02:59"
                          ></v-select
                        ></v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <div class="rest-time sec">
                    <v-row>
                      <v-col cols="2" class="day-time">
                        <v-row>
                          <v-col cols="6" class="lbl">昼休憩</v-col>
                          <v-col cols="4"
                            ><v-text-field
                              class="form-text"
                              dense
                              hide-details
                              outlined
                              placeholder="90"
                            ></v-text-field
                          ></v-col>
                          <v-col cols="1 pd-0" class="lbl">分</v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="3" class="night-time">
                        <v-row>
                          <v-col cols="3" class="lbl">夜休憩</v-col>
                          <v-col cols="4"
                            ><v-text-field
                              class="form-text"
                              dense
                              hide-details
                              outlined
                              placeholder="180"
                            ></v-text-field
                          ></v-col>
                          <v-col cols="1 pd-0" class="lbl">分</v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="4" class="tag-time">
                        <v-row>
                          <v-col cols="3" class="lbl">WTタグ</v-col>
                          <v-col cols="3" class="lbl"
                            ><input
                              type="checkbox"
                              class="form-text"
                              dense
                              hide-details
                              outlined
                            />
                            遅刻</v-col
                          >
                          <v-col cols="3" class="lbl"
                            ><input
                              type="checkbox"
                              dense
                              hide-details
                              outlined
                            />
                            遅刻</v-col
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="wt-memo sec">
                    <v-row>
                      <v-col cols="12" class="in-time">
                        <v-row>
                          <v-col cols="1" class="lbl">メモ</v-col>
                          <v-col cols="8"
                            ><v-text-field
                              class="form-text"
                              dense
                              hide-details
                              outlined
                              textarea
                              rows="4"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="wt-delete sec">
                    <v-row>
                      <v-col cols="12">
                        <svg
                          width="10"
                          height="12"
                          viewBox="0 0 10 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.40299 3.1393C1.12703 3.1393 0.883345 3.14302 0.639767 3.13821C0.363923 3.13285 0.186954 2.97021 0.187501 2.73221C0.188048 2.49268 0.363376 2.3296 0.64097 2.32851C1.42486 2.32501 2.20875 2.32719 3.02513 2.32719C3.02513 2.06054 3.02305 1.8104 3.02556 1.56037C3.03081 1.04018 3.36233 0.70757 3.88416 0.705601C4.66094 0.702539 5.43794 0.701992 6.21494 0.70582C6.74946 0.708335 7.07736 1.04204 7.08305 1.58005C7.08556 1.81598 7.08349 2.0519 7.08349 2.32709C7.18072 2.32709 7.26931 2.32709 7.35791 2.32709C8.03352 2.32709 8.70913 2.32665 9.38452 2.3273C9.72883 2.32774 9.91575 2.46851 9.9198 2.72718C9.92385 2.98684 9.7332 3.13766 9.39666 3.13909C9.17802 3.14018 8.95927 3.1393 8.70453 3.1393C8.70453 3.24977 8.70453 3.34723 8.70453 3.44479C8.70453 5.58963 8.70486 7.73458 8.70432 9.87965C8.70432 10.7715 8.22121 11.2508 7.32346 11.251C5.81189 11.2513 4.30022 11.2513 2.78866 11.251C1.87702 11.2508 1.4032 10.7786 1.40244 9.8593C1.40058 7.73119 1.40255 5.60319 1.40299 3.47509C1.40299 3.37479 1.40299 3.2746 1.40299 3.1393ZM2.22855 3.14543C2.22264 3.2011 2.21608 3.23348 2.21597 3.26596C2.21542 5.50498 2.21422 7.74377 2.21641 9.98279C2.21674 10.2905 2.37631 10.437 2.69624 10.4372C4.25942 10.4384 5.82239 10.4384 7.38547 10.4369C7.7486 10.4365 7.89013 10.2943 7.89035 9.92941C7.89166 7.74968 7.89111 5.56983 7.89122 3.39021C7.89122 3.31015 7.89122 3.23019 7.89122 3.14554C5.9846 3.14543 4.11844 3.14543 2.22855 3.14543ZM6.25639 2.31637C6.25639 2.03823 6.25639 1.78863 6.25639 1.53171C5.44767 1.53171 4.65263 1.53171 3.85517 1.53171C3.85517 1.80241 3.85517 2.0578 3.85517 2.31637C4.66214 2.31637 5.45139 2.31637 6.25639 2.31637Z"
                            fill="#AD4545"
                          />
                        </svg>
                        削除する
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
              <v-col cols="4" class="txt-right">
                <div class="save_link">
                  <a href>更新</a>
                </div>
                <div class="created_by">
                  <p class="created_date">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.04102 0.816406C10.413 0.825781 13.1983 3.59878 13.1799 6.96791C13.1609 10.3975 10.4396 13.121 7.02102 13.1144C3.58739 13.1082 0.873393 10.3619 0.886768 6.94816C0.899893 3.52566 3.63252 0.851656 7.04102 0.816406ZM7.04489 1.76828C4.17389 1.75391 1.84777 4.06578 1.82952 6.95028C1.81077 9.81978 4.11864 12.1473 7.00514 12.1714C9.88252 12.1949 12.2253 9.86791 12.2331 6.97791C12.2406 4.10366 9.92889 1.78153 7.04489 1.76828Z"
                        fill="#909090"
                      />
                    </svg>
                    2021/07/22 18:24
                  </p>
                  <p class="created_user">
                    <svg
                      width="10"
                      height="12"
                      viewBox="0 0 10 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.89686 10.64C9.87636 8.62172 8.58861 6.80097 6.69249 6.10897C6.62474 6.08434 6.55724 6.05809 6.48374 6.02947L6.24774 5.93847L6.35661 5.87234C7.92036 4.92259 8.10874 3.41647 7.79486 2.41509C7.42961 1.24997 6.34149 0.409969 5.14874 0.372719C5.11574 0.371594 5.08311 0.371094 5.05036 0.371094C3.79436 0.371094 2.71186 1.12347 2.29249 2.28784C1.80999 3.62659 2.23436 4.73397 3.66824 5.87859L3.74249 5.93784L3.65886 5.98247C3.64636 5.98922 3.63474 5.99597 3.62324 6.00259C3.59549 6.01872 3.56936 6.03359 3.54174 6.04372C1.30349 6.87347 0.127363 8.57972 0.140738 10.9652C0.143738 11.5085 0.257238 11.6251 0.814363 11.6251H8.92811C9.39524 11.6251 9.66536 11.6123 9.78224 11.4942C9.89849 11.377 9.90161 11.1025 9.89686 10.64ZM2.98249 3.30009C2.97824 2.75197 3.18786 2.24072 3.57274 1.85034C3.95461 1.46322 4.46236 1.25022 5.00286 1.25022H5.01586C6.13786 1.25022 7.05324 2.15447 7.05661 3.27272C7.05824 3.81559 6.84636 4.32547 6.46011 4.71334C6.07374 5.10147 5.56199 5.31422 5.01924 5.31497C3.90349 5.31497 2.99111 4.41022 2.98249 3.30009ZM9.01624 10.6792L9.01336 10.7502H1.02261L1.02011 10.6787C0.980613 9.68334 1.36324 8.68497 2.09786 7.92059C2.87111 7.11597 3.93999 6.62522 5.03074 6.62522H5.05299C6.13149 6.62522 7.19061 7.12834 7.95849 7.93784C8.68686 8.70559 9.06249 9.69797 9.01624 10.6792Z"
                        fill="#909090"
                      />
                    </svg>
                    By username
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Report',
  data() {
    return {
      isHidden: false
    }
  }
}
</script>

<style src="./Report.scss" lang="scss" scoped></style>
